import {ExtraOptions, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {MatchPublicPageGuard} from "./_guards/public-page.guard";
import {MatchPrivatePageGuard} from "./_guards/private-page.guard";
import {MatchPublicPageAuthActionsGuard} from "./_guards/public-page-auth-actions.guard";
import {MatchPublicPageBlogCategoriesGuard} from "./_guards/public-page-blog-categories.guard";
import {MatchPublicPageBlogPostGuard} from "./_guards/public-page-blog-post.guard";
import {MatchInsetGradeGuard} from "./_guards/isset-grade.guard";
import {MatchUnsetGradeGuard} from "./_guards/unset-grade.guard";
import {MatchPublicPageGradeGuard} from "./_guards/public-page-grade.guard";
import {MatchPublicPageSerieGuard} from "./_guards/public-page-serie.guard";
import {MatchNeedCheckPincodeGuard} from "./_guards/need-cheeck-pincode.guard";
import {MatchNoNeedCheckPincodeGuard} from "./_guards/no-need-cheeck-pincode.guard";
import {MatchRelationshipParentGuard} from "./_guards/relationship-parent.guard";
import {MatchRelationshipChildGuard} from "./_guards/relationship-child.guard";
import {MatchProtectPageGuard} from "./_guards/protect-page.guard";
import {MatchProtectPageAdmGuard} from "./_guards/protect-page-adm.guard";


const routerOptions: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  enableTracing: false,
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 30]
};

const _shorts_urls = [
  {
    path: "books",
    redirectTo: "/book-video-explanation"
  },
  {
    path: "introducingk",
    redirectTo: "/book-video-explanation/kindergarten-introducing-math-science-workbook"
  },
  {
    path: "introducing1",
    redirectTo: "/book-video-explanation/1st_grade_introducing_math_science_workbook"
  },
  {
    path: "introducing2",
    redirectTo: "/book-video-explanation/2nd-grade-introducing-math-science-workbook"
  },
  {
    path: "introducing3",
    redirectTo: "/book-video-explanation/3rd_grade_introducing_math_science_workbook"
  },
  {
    path: "ccmk",
    redirectTo: "/book-video-explanation/kindergarten_math_common_core_workbook_daily_practice"
  },
  {
    path: "ccm1",
    redirectTo: "/book-video-explanation/1st_grade_common_core_math_workbook_daily_practice_"
  },
  {
    path: "ccm2",
    redirectTo: "/book-video-explanation/2nd-grade-common-core-math-workbook-multiple-choice"
  },
  {
    path: "ccmfr2",
    redirectTo: "/book-video-explanation/2nd-grade-common-core-math-workbook-free-response"
  },
  {
    path: "ccm3",
    redirectTo: "/book-video-explanation/3rd-grade-common-core-math-workbook-multiple-choice"
  },
  {
    path: "ccmfr3",
    redirectTo: "/book-video-explanation/3rd-grade-common-core-math-workbook-free-response"
  },
  {
    path: "ccms3",
    redirectTo: "/book-video-explanation/3rd-grade-common-core-math-spanish-edition"
  },
  {
    path: "ccms4",
    redirectTo: "/book-video-explanation/4th-grade-common-core-math-spanish-edition"
  },
  {
    path: "ccms5",
    redirectTo: "/book-video-explanation/5th-grade-common-core-math-spanish-edition"
  },
  {
    path: "ccms1",
    redirectTo: "/book-video-explanation/1st-grade-common-core-math-spanish-edition"
  },
  {
    path: "ccms2",
    redirectTo: "/book-video-explanation/2nd-grade-common-core-math-spanish-edition"
  },
  {
    path: "ccm4",
    redirectTo: "/book-video-explanation/4th-grade-common-core-math-workbook-multiple-choice"
  },
  {
    path: "ccmfr4",
    redirectTo: "/book-video-explanation/4th-grade-common-core-math-workbook-free-response"
  },
  {
    path: "ccm5",
    redirectTo: "/book-video-explanation/5th-grade-common-core-math-workbook-multiple-choice"
  },
  {
    path: "ccmfr5",
    redirectTo: "/book-video-explanation/5th-grade-common-core-math-workbook-free-response"
  },
  {
    path: "ccm6",
    redirectTo: "/book-video-explanation/6th-grade-common-core-math-workbook-multiple-choice"
  },
  {
    path: "ccmfr6",
    redirectTo: "/book-video-explanation/6th-grade-common-core-math-workbook-free-response"
  },
  {
    path: "ccm7",
    redirectTo: "/book-video-explanation/7th-grade-common-core-math-workbook-multiple-choice"
  },
  {
    path: "ccmfr7",
    redirectTo: "/book-video-explanation/7th-grade-common-core-math-workbook-free-response"
  },
  {
    path: "ccm8",
    redirectTo: "/book-video-explanation/8th-grade-common-core-math-workbook-daily-practice"
  },
  {
    path: "introk",
    redirectTo: "/book-video-explanation/introducing_math_kindergarten_math_500_practice_questions"
  },
  {
    path: "intro1",
    redirectTo: "/book-video-explanation/introducing-math-grade-1-by-argoprep"
  },
  {
    path: "intro2",
    redirectTo: "/book-video-explanation/introducing-math-grade-2-by-argoprep"
  },
  {
    path: "intro3",
    redirectTo: "/book-video-explanation/introducing-math-grade-2-by-argoprep"
  },
  {
    path: "intro4",
    redirectTo: "/book-video-explanation/introducing-math-grade-4-by-argoprep"
  },
  {
    path: "intro5",
    redirectTo: "/book-video-explanation/introducing-math-grade-5-by-argoprep"
  },
  {
    path: "intro6",
    redirectTo: "/book-video-explanation/introducing_math_grade_6_by_argoprep_600_practice_questions"
  },
  {
    path: "intro7",
    redirectTo: "/book-video-explanation/introducing-math-grade-7-by-argoprep_600_practice_questions"
  },
  {
    path: "intro8",
    redirectTo: "/book-video-explanation/introducing-math-grade-8-by-argoprep"
  },
  {
    path: "socialk",
    redirectTo: "/book-video-explanation/kindergarten_social_studies_daily_practice_workbook"
  },
  {
    path: "social1",
    redirectTo: "/book-video-explanation/1st_grade_social_studies_daily_practice_workbook"
  },
  {
    path: "social2",
    redirectTo: "/book-video-explanation/2nd-grade-social-studies-daily-practice-workbook"
  },
  {
    path: "social3",
    redirectTo: "/book-video-explanation/3rd_grade_social_studies_daily_practice_workbook"
  },
  {
    path: "social4",
    redirectTo: "/book-video-explanation/4th_grade_social_studies_daily_practice_workbook"
  },
  {
    path: "social5",
    redirectTo: "/book-video-explanation/5th-grade-social-studies-daily-practice-workbook"
  },
  {
    path: "social6",
    redirectTo: "/book-video-explanation/6th-grade-social-studies-daily-practice-workbook"
  },
  {
    path: "social7",
    redirectTo: "/book-video-explanation/7th-grade-social-studies-daily-practice-workbook"
  },
  {
    path: "social8",
    redirectTo: "/book-video-explanation/8th_grade_social_studies_daily_practice_workbook"
  },
  {
    path: "elak",
    redirectTo: "/book-video-explanation/common_core_kindergarten_ela_workbook_daily_practice"
  },
  {
    path: "ela1",
    redirectTo: "/book-video-explanation/1st_grade_common_core_ela_workbook_english_language_arts_"
  },
  {
    path: "ela2",
    redirectTo: "/book-video-explanation/2nd_grade_common_core_ela_english_language_arts_"
  },
  {
    path: "ela3",
    redirectTo: "/book-video-explanation/3rd_grade_common_core_ela_english_language_arts_daily_practice_workbook_"
  },
  {
    path: "ela4",
    redirectTo: "/book-video-explanation/4th_grade_common_core_ela_english_language_arts_daily_practice_workbook_"
  },
  {
    path: "ela5",
    redirectTo: "/book-video-explanation/5th-grade-common-core-ela-english-language-arts"
  },
  {
    path: "ela6",
    redirectTo: "/book-video-explanation/6th-grade-common-core-ela-english-language-arts"
  },
  {
    path: "ela7",
    redirectTo: "/book-video-explanation/7th_grade_common_core_ela_english_language_arts_daily_practice_workbook_"
  },
  {
    path: "ela8",
    redirectTo: "/book-video-explanation/8th_grade_common_core_ela_english_language_arts_daily_practice_workbook_"
  },
  {
    path: "sciencek",
    redirectTo: "/book-video-explanation/kindergarten-science-daily-practice-workbook-20-weeks-of-fun-activities"
  },
  {
    path: "science1",
    redirectTo: "/book-video-explanation/1st_grade_science_daily_practice_workbook_20_weeks_of_fun_activities"
  },
  {
    path: "science2",
    redirectTo: "/book-video-explanation/2nd_grade_science_daily_practice_workbook_20_weeks_of_fun_activities"
  },
  {
    path: "science3",
    redirectTo: "/book-video-explanation/3rd_grade_science_daily_practice_workbook_20_weeks_of_fun_activities"
  },
  {
    path: "science4",
    redirectTo: "/book-video-explanation/4th-grade-science-daily-practice-workbook-20-weeks-of-fun-activities"
  },
  {
    path: "science5",
    redirectTo: "/book-video-explanation/5th_grade_science_daily_practice_workbook_20_weeks_of_fun_activities"
  },
  {
    path: "science6",
    redirectTo: "/book-video-explanation/6th_grade_science_daily_practice_workbook_20_weeks_of_fun"
  },
  {
    path: "science7",
    redirectTo: "/book-video-explanation/7th_grade_science_daily_practice_workbook_20_weeks_of_fun_activities"
  },
  {
    path: "science8",
    redirectTo: "/book-video-explanation/8th_grade_science_daily_practice_workbook_20_weeks_of_fun_activities"
  },
  {
    path: "summerprek",
    redirectTo: "/book-video-explanation/kids_summer_academy_by_argoprep_grade_prek_k"
  },
  {
    path: "summerk",
    redirectTo: "/book-video-explanation/kids_summer_academy_by_argoprep_grade_k_1"
  },
  {
    path: "summer10",
    redirectTo: "/book-video-explanation/kids-summer-academy-by-argoprep-grade-10-11"
  },
  {
    path: "summer1",
    redirectTo: "/book-video-explanation/kids-summer-academy-by-argoprep-grades-1-2"
  },
  {
    path: "summer2",
    redirectTo: "/book-video-explanation/kids_summer_academy_by_argoprep_grade_2_3"
  },
  {
    path: "summer3",
    redirectTo: "/book-video-explanation/kids-summer-academy-by-argoprep-grades-3-4"
  },
  {
    path: "summer4",
    redirectTo: "/book-video-explanation/kids-summer-academy-by-argoprep-grades-4-5"
  },
  {
    path: "summer5",
    redirectTo: "/book-video-explanation/kids_summer_academy_by_argoprep_grade_5_6"
  },
  {
    path: "summer6",
    redirectTo: "/book-video-explanation/kids_summer_academy_by_argoprep_grade_6_7"
  },
  {
    path: "summer7",
    redirectTo: "/book-video-explanation/kids-summer-academy-by-argoprep-grades-7-8"
  },
  {
    path: "summer8",
    redirectTo: "/book-video-explanation/kids_summer_academy_by_argoprep_grade_8_9"
  },
  {
    path: "summer9",
    redirectTo: "/book-video-explanation/kids-summer-academy-by-argoprep-grade-9-10"
  },
  {
    path: "winterk",
    redirectTo: "/book-video-explanation/kids_winter_academy_by_argoprep_kindergarten"
  },
  {
    path: "winter1",
    redirectTo: "/book-video-explanation/kids-winter-academy-by-argoprep-grade-1"
  },
  {
    path: "winter2",
    redirectTo: "/book-video-explanation/kids-winter-academy-by-argoprep-grade-2"
  },
  {
    path: "winter3",
    redirectTo: "/book-video-explanation/kids-winter-academy-by-argoprep-grade-3"
  },
  {
    path: "winter4",
    redirectTo: "/book-video-explanation/kids-winter-academy-by-argoprep-grade-4"
  },
  {
    path: "winter5",
    redirectTo: "/book-video-explanation/kids-winter-academy-by-argoprep-grade-5"
  },
  {
    path: "winter6",
    redirectTo: "/book-video-explanation/kids-winter-academy-by-argoprep-grade-6"
  },
  {
    path: "winter7",
    redirectTo: "/book-video-explanation/kids-winter-academy-by-argoprep-grade-7"
  },
  {
    path: "winter8",
    redirectTo: "/book-video-explanation/kids_winter_academy_by_argoprep_grade_8"
  },
  {
    path: "shsat1",
    redirectTo: "/book-video-explanation/shsat_prep_7th_grade_math_standards_everyday_practice_questions_book_1_"
  },
  {
    path: "shsat2",
    redirectTo: "/book-video-explanation/shsat_prep_158_revising/editing_book_2_"
  },
  {
    path: "shsat3",
    redirectTo: "/book-video-explanation/shsat_prep_1_000_math_practice_questions_book_3_"
  },
  {
    path: "shsat5",
    redirectTo: "/book-video-explanation/shsat_prep_5_full_length_exams_book_4_"
  },
  {
    path: "spelling1",
    redirectTo: "/book-video-explanation/1st-grade-spelling-and-grammar-daily-practice-workbook"
  },
  {
    path: "spelling2",
    redirectTo: "/book-video-explanation/2nd_grade_spelling_and_grammar_daily_practice_workbook"
  },
  {
    path: "spelling3",
    redirectTo: "/book-video-explanation/3rd-grade-spelling-and-grammar-daily-practice-workbook"
  },
  {
    path: "spelling4",
    redirectTo: "/book-video-explanation/4th-grade-spelling-and-grammar-daily-practice-workbook"
  },
  {
    path: "mathtest1",
    redirectTo: "/book-video-explanation/1st-grade-math-practice-book-8-full-length-simulated-common-core-math-tests"
  },
  {
    path: "mathtest2",
    redirectTo: "/book-video-explanation/2nd-grade-math-practice-book-8-full-length-simulated-common-core-math-tests"
  },
  {
    path: "mathtest3",
    redirectTo: "/book-video-explanation/3rd_grade_math_practice_book_8_full_length_simulated_math_tests"
  },
  {
    path: "mathtest4",
    redirectTo: "/book-video-explanation/4th-grade-math-practice-book-8-full-length-simulated-common-core-math-tests"
  },
  {
    path: "mathtest5",
    redirectTo: "/book-video-explanation/5th_grade_math_practice_book_8_full_length_simulated_math_tests"
  }
];

const _routing = [
  {
    path: '',
    canMatch:[MatchNeedCheckPincodeGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
      },
      {
        path: '**',
        loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
      }
    ]
  },
  {
    path: '',
    canMatch:[MatchNoNeedCheckPincodeGuard],
    children: [
      {
        path: '',
        children: [
            ..._shorts_urls,
          {
            path: 'apc',
            children: [
              {
                path: '',
                canMatch: [MatchProtectPageGuard],
                children: [
                  {
                    path: 'health',
                    canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                    loadChildren: () => import('./_ui/pages/protected/bh-page-apc-health/bh-page-apc-health.module').then((mod) => mod.BhPageApcHealthModule),
                  },
                  {
                    path: 'users',
                    canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard],
                    children: [
                      {
                        path: 'list',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-apc-users-list/bh-page-apc-users-list.module').then((mod) => mod.BhPageApcUsersListModule),
                      },
                      {
                        path: 'promo',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-apc-users-promo/bh-page-apc-users-promo.module').then((mod) => mod.BhPageApcUsersPromoModule),
                      },
                      {
                        path: ':user_id',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-apc-user-edit/bh-page-apc-user-edit.module').then((mod) => mod.BhPageApcUserEditModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-apc-users/bh-page-apc-users.module').then((mod) => mod.BhPageApcUsersModule),
                      }
                    ]
                  },

                  {
                    path: 'workbooks',
                    canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                    children: [
                      {
                        path: 'bundles',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-bundles/bh-page-bundles.module').then((mod) => mod.BhPageBundlesModule),
                      },
                      {
                        path: ':workbook_id',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-workbook/bh-page-workbook.module').then((mod) => mod.BhPageWorkbookModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-workbooks/bh-page-workbooks.module').then((mod) => mod.BhPageWorkbooksModule),
                      }
                    ]
                  },
                  {
                    path: 'categories',
                    canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                    children: [
                      {
                        path: 'plans/:grade_id',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-plans/bh-page-plans.module').then((mod) => mod.BhPagePlansModule),
                      },
                      {
                        path: 'plans',
                        redirectTo: '/apc/categories',
                      },
                      {
                        path: ':grade_category_id',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-category/bh-page-category.module').then((mod) => mod.BhPageCategoryModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard, MatchProtectPageAdmGuard],
                        loadChildren: () => import('./_ui/pages/protected/bh-page-categories/bh-page-categories.module').then((mod) => mod.BhPageCategoriesModule),
                      }
                    ]
                  },
                  {
                    path: '',
                    canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard],
                    loadChildren: () => import('./_ui/pages/protected/bh-page-apc/bh-page-apc.module').then((mod) => mod.BhPageApcModule),
                  },    //base path
                  {
                    path: '**',
                    redirectTo: '/apc',
                  },  //all children redirect to base path
                ]
              }, //protected
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                children: [
                  {
                    path: '**',
                    redirectTo: '/dashboard',
                  },  //all children redirect to base path
                ]
              }, //private
              {
                path: '',
                canMatch: [MatchPublicPageGuard],
                children:[
                  {
                    path: '',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                ]
              }  //public
            ]
          },
          {
            path: 'auth',
            children: [
              {
                path: ':action/:token',
                canMatch: [MatchPublicPageGuard, MatchPublicPageAuthActionsGuard],
                loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
                data: {
                  noindex: true,
                }
              },
              {
                path: ':action',
                canMatch: [MatchPublicPageGuard, MatchPublicPageAuthActionsGuard],
                loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
                data: {
                  noindex: true,
                }
              },
              {
                path: '',
                canMatch: [MatchPublicPageGuard],
                loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
                data: {
                  noindex: true,
                }
              },
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-auth-redirect/bh-page-auth-redirect.module').then((mod) => mod.BhPageAuthRedirectModule),
                data: {
                  noindex: true
                }
              },
            ]
          },
          {
            path: 'terms',
            loadChildren: () => import('./_ui/pages/public/bh-page-terms/bh-page-terms.module').then((mod) => mod.BhPageTermsModule),
          },
          {
            path: 'privacy-policy',
            loadChildren: () => import('./_ui/pages/public/bh-page-privacy-policy/bh-page-privacy-policy.module').then((mod) => mod.BhPagePrivacyPolicyModule),
          },
          {
            path: 'contacts',
            loadChildren: () => import('./_ui/pages/public/bh-page-contacts/bh-page-contacts.module').then((mod) => mod.BhPageContactsModule),
          },
          {
            path: 'store',
            children: [
              {
                path: 'cart',
                loadChildren: () => import('./_ui/pages/public/bh-page-store-cart/bh-page-store-cart.module').then((mod) => mod.BhPageStoreCartModule),
              },
              {
                path: 'checkout',
                children:[
                  {
                    path: '',
                    canMatch: [MatchRelationshipParentGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-store-checkout/bh-page-store-checkout.module').then((mod) => mod.BhPageStoreCheckoutModule),
                  },
                  {
                    path: '',
                    canMatch: [MatchRelationshipChildGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
                  }
                ]

              },
              {
                path: 'product/:workbook_slug',
                loadChildren: () => import('./_ui/pages/public/bh-page-workbook/bh-page-workbook.module').then((mod) => mod.BhPageWorkbookModule),
              },
              {
                path: 'product',
                redirectTo: '/store/listing',
              },
              {
                path: 'listing/:filter_grade',
                canMatch: [MatchPublicPageGradeGuard],
                loadChildren: () => import('./_ui/pages/public/bh-page-store-listing/bh-page-store-listing.module').then((mod) => mod.BhPageStoreListingModule),
              },
              {
                path: 'listing/:filter',
                redirectTo: '/store/listing',
              },
              {
                path: ':serie',
                canMatch: [MatchPublicPageSerieGuard],
                loadChildren: () => import('./_ui/pages/public/bh-page-store-series/bh-page-store-series.module').then((mod) => mod.BhPageStoreSeriesModule),
              },
              {
                path: 'listing',
                loadChildren: () => import('./_ui/pages/public/bh-page-store-listing/bh-page-store-listing.module').then((mod) => mod.BhPageStoreListingModule),
              },
              {
                path: '',
                loadChildren: () => import('./_ui/pages/public/bh-page-store/bh-page-store.module').then((mod) => mod.BhPageStoreModule),
              },
            ]
          },
          {
            path: 'worksheets',
            children: [
              {
                path: ':category/:subcategory/:topic/:subtopic/:part/:subpart',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
              },
              {
                path: ':category/:subcategory/:topic/:subtopic/:part',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
              },
              {
                path: ':category/:subcategory/:topic/:subtopic',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
              },
              {
                path: ':category/:subcategory/:topic',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
              },
              {
                path: ':category/:subcategory',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
              },
              {
                path: ':category',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
              },
              {
                path: '',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
              },
            ]
          },
          {
            path: 'worksheet',
            children: [
              {
                path: ':worksheet_slug',
                loadChildren: () => import('./_ui/pages/public/bh-page-worksheet/bh-page-worksheet.module').then((mod) => mod.BhPageWorksheetModule),
              }
            ]
          },
          {
            path: 'blog',
            children: [
              {
                path: 'page/:page_num',
                loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
              },
              {
                path: ':category/page/:page_num',
                loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
                canMatch:[MatchPublicPageBlogCategoriesGuard]
              },
              {
                path: ':category',
                loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
                canMatch:[MatchPublicPageBlogCategoriesGuard]
              },
              {
                path: ':post',
                loadChildren: () => import('./_ui/pages/public/bh-page-blog-post/bh-page-blog-post.module').then((mod) => mod.BhPageBlogPostModule),
                canMatch:[MatchPublicPageBlogPostGuard],
              },
              {
                path: '',
                loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
              },
              {
                path: '**',
                redirectTo: '/blog'
              }
            ]
          },
          {
            path: 'book-video-explanation',
            children: [
              {
                path: ':workbook_slug',
                loadChildren: () => import('./_ui/pages/public/bh-page-bve-single/bh-page-bve-single.module').then((mod) => mod.BhPageBveSingleModule),
              },
              {
                path: '',
                loadChildren: () => import('./_ui/pages/public/bh-page-bve/bh-page-bve.module').then((mod) => mod.BhPageBveModule),
              },
            ]
          },
          {
            path: 'account',
            children: [
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                children:[
                  {
                    path: 'pin',
                    loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
                  },
                  {
                    path: 'result',
                    canMatch: [MatchPrivatePageGuard, MatchProtectPageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-account-result/bh-page-account-result.module').then((mod) => mod.BhPageAccountResultModule),
                  },
                  {
                    path: 'children',
                    canMatch: [MatchPrivatePageGuard],
                    children:[
                      {
                        path: '',
                        canMatch: [MatchRelationshipParentGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-account-children/bh-page-account-children.module').then((mod) => mod.BhPageAccountChildrenModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchRelationshipChildGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
                      }
                    ]
                  },
                  {
                    path: 'subscriptions',
                    canMatch: [MatchPrivatePageGuard],
                    children:[
                      {
                        path: '',
                        canMatch: [MatchRelationshipParentGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-account-subscriptions/bh-page-account-subscriptions.module').then((mod) => mod.BhPageAccountSubscriptionsModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchRelationshipChildGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
                      }
                    ]
                  },
                  {
                    path: 'purchases',
                    canMatch: [MatchPrivatePageGuard],
                    children:[
                      {
                        path: '',
                        canMatch: [MatchRelationshipParentGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-account-purchases/bh-page-account-purchases.module').then((mod) => mod.BhPageAccountPurchasesModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchRelationshipChildGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
                      }
                    ]

                  },
                  {
                    path: 'workbooks/special',
                    canMatch: [MatchPrivatePageGuard],
                    children:[
                      {
                        path: '',
                        canMatch: [MatchRelationshipParentGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-special-workbooks/bh-page-special-workbooks.module').then((mod) => mod.BhPageSpecialWorkbooksModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchRelationshipChildGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
                      }
                    ]

                  },
                  {
                    path: 'workbooks/e-books',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-account-workbooks/bh-page-account-workbooks.module').then((mod) => mod.BhPageAccountWorkbooksModule),
                  },
                  {
                    path: 'workbooks',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-account-workbooks/bh-page-account-workbooks.module').then((mod) => mod.BhPageAccountWorkbooksModule),
                  },
                  {
                    path: 'downloads',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-account-downloads/bh-page-account-downloads.module').then((mod) => mod.BhPageAccountDownloadsModule),
                  },
                  {
                    path: 'awards',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-account-awards/bh-page-account-awards.module').then((mod) => mod.BhPageAccountAwardsModule),
                  },
                  {
                    path: 'change',
                    canMatch: [MatchPrivatePageGuard],
                    children:[
                      {
                        path: '',
                        canMatch: [MatchRelationshipParentGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-grades/bh-page-grades.module').then((mod) => mod.BhPageGradesModule),
                      },
                      {
                        path: '',
                        canMatch: [MatchRelationshipChildGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-pincode/bh-page-pincode.module').then((mod) => mod.BhPagePincodeModule),
                      }
                    ]

                  },
                  {
                    path: 'logout',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-account-logout/bh-page-account-logout.module').then((mod) => mod.BhPageAccountLogoutModule),
                  },
                  {
                    path: '',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-account/bh-page-account.module').then((mod) => mod.BhPageAccountModule),
                  },
                ]
              },
              {
                path: '',
                canMatch: [MatchPublicPageGuard],
                children:[
                  {
                    path: 'subscriptions',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'purchases',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'workbooks/special',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'workbooks',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'downloads',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'awards',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'children',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'change',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: 'logout',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                  {
                    path: '',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                ]
              }
            ]
          },
          {
            path: 'dashboard',
            children: [
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                children: [
                  {
                    path: '',
                    canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-dashboard/bh-page-dashboard.module').then((mod) => mod.BhPageDashboardModule),
                  },    //base path
                  {
                    path: '',
                    canMatch: [MatchPrivatePageGuard, MatchUnsetGradeGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-grades/bh-page-grades.module').then((mod) => mod.BhPageGradesModule),
                  },    //grade
                  {
                    path: '**',
                    redirectTo: '/dashboard',
                  },  //all children redirect to base path
                ]
              }, //private
              {
                path: '',
                canMatch: [MatchPublicPageGuard],
                children:[
                  {
                    path: '',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                ]
              }  //public
            ]
          },
          {
            path: 'practices',
            children: [
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                children: [
                  {
                    path: '',
                    canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                    children:[
                      {
                        path: 'practice_drills/:subject/:quiz_id/result',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //canDeactivate: [CanExitQuizGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-quiz-result/bh-page-practices-quiz-result.module').then((mod) => mod.BhPagePracticesQuizResultModule),
                      },
                      {
                        path: 'practice_drills/:subject/:quiz_id',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //canDeactivate: [CanExitQuizGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-quiz/bh-page-practices-quiz.module').then((mod) => mod.BhPagePracticesQuizModule),
                      },
                      {
                        path: 'practice_drills/:subject',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //canDeactivate: [CanExitQuizGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-drills/bh-page-practices-drills.module').then((mod) => mod.BhPagePracticesDrillsModule),
                      },
                      {
                        path: 'practice_drills',
                        redirectTo: '/practices/practice_drills/math',
                        //canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //loadChildren: () => import('./_ui/pages/private/bh-page-practices-drills/bh-page-practices-drills.module').then((mod) => mod.BhPagePracticesDrillsModule),
                      },
                      {
                        path: 'practice_questions/:subject/:quiz_id/result',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //canDeactivate: [CanExitQuizGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-quiz-result/bh-page-practices-quiz-result.module').then((mod) => mod.BhPagePracticesQuizResultModule),
                      },
                      {
                        path: 'practice_questions/:subject/:quiz_id',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //canDeactivate: [CanExitQuizGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-quiz/bh-page-practices-quiz.module').then((mod) => mod.BhPagePracticesQuizModule),
                      },
                      {
                        path: 'practice_questions/:subject',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //canDeactivate: [CanExitQuizGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-questions/bh-page-practices-questions.module').then((mod) => mod.BhPagePracticesQuestionsModule),
                      },
                      {
                        path: 'practice_questions',
                        redirectTo: '/practices/practice_questions/math',
                        //canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //loadChildren: () => import('./_ui/pages/private/bh-page-practices-drills/bh-page-practices-drills.module').then((mod) => mod.BhPagePracticesDrillsModule),
                      },
                      {
                        path: 'video_lectures/:subject/:lectures_id',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-video/bh-page-practices-video.module').then((mod) => mod.BhPagePracticesVideoModule),
                      },
                      {
                        path: 'video_lectures/:subject',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-video-lectures/bh-page-practices-video-lectures.module').then((mod) => mod.BhPagePracticesVideoLecturesModule),
                      },
                      {
                        path: 'video_lectures',
                        redirectTo: '/practices/video_lectures/math',
                        //canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //loadChildren: () => import('./_ui/pages/private/bh-page-practices-video-lectures/bh-page-practices-video-lectures.module').then((mod) => mod.BhPagePracticesVideoLecturesModule),
                      },

                      {
                        path: 'official_test_explanations/:subject/:lectures_id',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-video/bh-page-practices-video.module').then((mod) => mod.BhPagePracticesVideoModule),
                      },
                      {
                        path: 'official_test_explanations/:subject',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-official-test-explanations/bh-page-practices-official-test-explanations.module').then((mod) => mod.BhPagePracticesOfficialTestExplanationsModule),
                      },
                      {
                        path: 'official_test_explanations',
                        redirectTo: '/practices/official_test_explanations/math',
                        //canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        //loadChildren: () => import('./_ui/pages/private/bh-page-practices-video-lectures/bh-page-practices-video-lectures.module').then((mod) => mod.BhPagePracticesVideoLecturesModule),
                      },

                      {
                        path: 'worksheets/:subject/:worksheet_id',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-worksheet/bh-page-practices-worksheet.module').then((mod) => mod.BhPagePracticesWorksheetModule),
                      },
                      {
                        path: 'worksheets/:subject',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-worksheets/bh-page-practices-worksheets.module').then((mod) => mod.BhPagePracticesWorksheetsModule),
                      },
                      {
                        path: 'worksheets',
                        redirectTo: '/practices/worksheets/math',
                      },

                      {
                        path: 'games/:subject',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices-games/bh-page-practices-games.module').then((mod) => mod.BhPagePracticesGamesModule),
                      },


                      {
                        path: '',
                        canMatch: [MatchPrivatePageGuard, MatchInsetGradeGuard],
                        loadChildren: () => import('./_ui/pages/private/bh-page-practices/bh-page-practices.module').then((mod) => mod.BhPagePracticesModule),
                      },
                    ]
                  },    //base path
                  {
                    path: '',
                    canMatch: [MatchPrivatePageGuard, MatchUnsetGradeGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-grades/bh-page-grades.module').then((mod) => mod.BhPageGradesModule),
                  },    //grade
                  {
                    path: '**',
                    redirectTo: '/practices',
                  },  //all children redirect to base path
                ]
              }, //private
              {
                path: '',
                canMatch: [MatchPublicPageGuard],
                children:[
                  {
                    path: '',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                ]
              }  //public
            ]
          },
          {
            path: 'e-book',
            children: [
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                children: [
                  {
                    path: ':workbook_slug/:page',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-e-book/bh-page-e-book.module').then((mod) => mod.BhPageEBookModule),
                  },    //base path
                  {
                    path: ':workbook_slug',
                    canMatch: [MatchPrivatePageGuard],
                    loadChildren: () => import('./_ui/pages/private/bh-page-e-book/bh-page-e-book.module').then((mod) => mod.BhPageEBookModule),
                  },    //base path
                  {
                    path: '**',
                    redirectTo: '/account/workbooks/e-books',
                  },  //all children redirect to base path
                ]
              }, //private
              {
                path: '',
                canMatch: [MatchPublicPageGuard],
                children:[
                  {
                    path: '',
                    canMatch: [MatchPublicPageGuard],
                    loadChildren: () => import('./_ui/pages/public/bh-page-no-auth-redirect/bh-page-no-auth-redirect.module').then((mod) => mod.BhPageNoAuthRedirectModule),
                  },
                ]
              }  //public
            ]
          },


          {
            path: 'k12',
            loadChildren: () => import('./_ui/pages/public/bh-page-landing-k12/bh-page-landing-k12.module').then((mod) => mod.BhPageLandingK12Module),
          },
          {
            path: 'shsat',
            loadChildren: () => import('./_ui/pages/public/bh-page-landing-shsat/bh-page-landing-shsat.module').then((mod) => mod.BhPageLandingShsatModule),
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
          },
          {
            path: '**',
            loadChildren: () => import('./_ui/pages/public/bh-page-not-found/bh-page-not-found.module').then((mod) => mod.BhPageNotFoundModule),
          }
        ]
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([], routerOptions),
    RouterModule.forChild(_routing),
  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
